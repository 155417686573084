<template>
    <l-map
        :options="{ attributionControl: false, zoomControl: false }"
        :zoom="zoom"
        :center="marker"
        style="width: 100%; height: 15rem"
    >
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker :lat-lng="marker" @click="shopGoogleMap"></l-marker>
    </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
    },
    props: {
        marker: {
            require: true,
            type: Array,
        },
        googleMap: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 15,
        };
    },
    methods: {
        shopGoogleMap() {
            if (this.googleMap === 'shopOne') {
                window.open(
                    'https://www.google.com/maps/place/Pyae+Sone+Shin+Gold+%26+Jewellery/@16.7783975,96.1533472,18.14z/data=!4m7!3m6!1s0x30c1ec81f4b8cc0b:0x9ba4b16d73c96d3d!4b1!8m2!3d16.7789162!4d96.153963!16s%2Fg%2F11ggzlt0v3?hl=en-MM',
                    '_blank',
                );
            } else {
                window.open(
                    'https://maps.app.goo.gl/iLhUZXzDcyouHSfS9?g_st=ic',
                    '_blank',
                );
            }
        },
    },
};
</script>
