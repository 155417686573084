<template>
    <div class="container py-5">
        <div
            class="d-flex flex-column flex-lg-row justify-content-between align-items-center gap-5"
        >
            <div>
                <img
                    src="@/assets/img/shop/JC.jpg"
                    class="d-none ali d-md-block"
                    style="width: 100%;height:20rem; border-radius:15px"
                />
                <div
                    class="d-flex flex-column flex-md-row align-items-center gap-3 mt-3"
                >
                    <!-- map -->
                    <!-- <div> -->
                    <leaflet-map
                        :marker="location_one"
                        class="map"
                        googleMap="shopOne"
                    />
                    <!-- </div> -->
                    <!-- address -->
                    <div class="text-center text-md-start location">
                        <p>Pyae Sone Shin</p>
                        <h2 class="m-0 fw-bold">Junction City</h2>
                        <div
                            class="d-flex flex-column my-3"
                            style="font-size: 15px"
                        >
                            <span>First Floor (level-2), C - (007,008)</span>
                            <span>Gems & Souvenir Zone</span>
                            <span>
                                Corner of Shewdagon Pagoda Road and Bogyoke
                                Road, Pabedan Township, Yangon
                            </span>
                        </div>
                        <div
                            class="d-flex justify-content-center justify-content-md-start align-items-center"
                        >
                            <span>09 785 018 374</span>
                            ,
                            <span>09 780 177 177</span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <img
                    src="@/assets/img/shop/TC.jpg"
                    class="d-none ali d-md-block"
                    style="width: 100%;height:20rem; border-radius:15px"
                />
                <div
                    class="d-flex flex-column flex-md-row align-items-center gap-3 mt-3"
                >
                    <!-- map -->
                    <!-- <div> -->
                    <leaflet-map
                        :marker="location_two"
                        class="map"
                        googleMap="shopTwo"
                    />
                    <!-- </div> -->
                    <!-- address -->
                    <div class="text-center text-md-start location">
                        <p>Pyae Sone Shin</p>
                        <h2 class="m-0 fw-bold">Times City</h2>
                        <div
                            class="d-flex flex-column my-3"
                            style="font-size: 15px"
                        >
                            <span>B-211, B-227, B-229, B-230</span>
                            <span>Second Floor, Jewellery Mall, Time City</span>
                            <span>
                                Between Hanthawaddy Road and Kyun Taw Road
                                Kamayut Township, Yangon
                            </span>
                        </div>
                        <div
                            class="d-flex justify-content-center justify-content-md-start align-items-center"
                        >
                            <span>09 783 122 122</span>
                            ,
                            <span>09 784 122 122</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeafletMap from './LeafletMap.vue';
export default {
    components: {
        'leaflet-map': LeafletMap,
    },
    data() {
        return {
            location_one: [16.7789505, 96.1530665],
            location_two: [16.8163641, 96.1276623],
        };
    },
};
</script>

<style lang="scss" scoped>
// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
.map,
.location {
    width: 100%;
}
</style>
